
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import LogoDisplayer from "@evercam/shared/components/LogoDisplayer"
import { SharePermission } from "@evercam/shared/types/shares"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelize } from "humps"
import { debounce } from "@evercam/shared/utils"

export default {
  components: {
    EvercamLoadingAnimation,
    LogoDisplayer,
  },
  data() {
    return {
      users: [],
      projects: [],
      projectShares: {},
      requestInProgress: false,
      userSearch: "",
      panel: [],
      itemsPerPage: 10,
      startRange: 1,
      endRange: 10,
    }
  },
  computed: {
    filteredUsers() {
      let users = this.users
      if (!this.accountStore.email.includes("@evercam.io")) {
        users = users.filter(({ email }) => {
          return email !== "demo@evercam.io"
        })
      }

      if (!this.enableSearch) {
        return this.users.slice(this.startRange - 1, this.endRange)
      }

      return users
        .filter(
          (user) =>
            user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
            user.email.toLowerCase().includes(this.userSearch.toLowerCase())
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    enableSearch() {
      return this.userSearch.length >= 3
    },
    totalUsers() {
      return this.enableSearch ? this.filteredUsers.length : this.users.length
    },
    ...mapStores(useProjectStore, useCameraStore, useAccountStore),
    rights() {
      return [
        {
          text: this.$t("content.shares.create_form.rights_labels.none"),
          value: null,
          color: "grey",
          visible: true,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.read_only"),
          value: SharePermission.Minimum,
          color: "green",
          visible: true,
        },
        {
          text: this.$t(
            "content.shares.create_form.rights_labels.read_only_share"
          ),
          value: SharePermission.Share,
          color: "primary",
          visible: true,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.full"),
          value: SharePermission.Full,
          color: "primary",
          visible: true,
        },
        {
          text: this.$t("content.shares.create_form.rights_labels.custom"),
          value: SharePermission.Custom,
          color: "primary",
          visible: false,
        },
      ]
    },
    gridHeaderWidth() {
      return {
        width: this.noSearchResult
          ? "100%"
          : `${(this.filteredUsers.length + 1) * 252}px`,
      }
    },
    gridContentWidth() {
      return {
        width: `${this.filteredUsers.length * 252}px`,
      }
    },
    projectUserAccess() {
      let projectUserAccess = {}

      for (const project of this.projects) {
        for (const user of this.users) {
          const projectAccess = this.getUserProjectAccessLevel(
            user.email,
            project.exid
          )
          projectUserAccess[project.exid] = {
            ...projectUserAccess[project.exid],
            [user.email]: projectAccess,
          }
        }
      }

      return projectUserAccess
    },
    noSearchResult() {
      return this.enableSearch && this.filteredUsers.length === 0
    },
    previousPageDisabled() {
      return this.startRange === 1
    },
    nextPageDisabled() {
      return this.endRange >= this.totalUsers
    },
    debouncedUserSearch: {
      get() {
        return this.userSearch
      },
      set: debounce(function (newValue) {
        this.userSearch = newValue
      }, 100),
    },
  },
  async mounted() {
    this.requestInProgress = true
    await this.fetchProjectShares()
    this.requestInProgress = false
  },
  methods: {
    getCameraPermission(rights) {
      if (rights.indexOf("edit") !== -1) {
        return SharePermission.Full
      } else if (rights.indexOf("share") !== -1) {
        return SharePermission.Share
      }

      return SharePermission.Minimum
    },
    async formatProjectShares() {
      const projects = []

      for (const project of this.projectStore.projects) {
        const cameras = this.cameraStore.cameras.filter(
          (camera) => camera.project.id === project.exid
        )

        const projectShares = this.projectShares[project.exid]
        const projectData = {
          name: project.name,
          exid: project.exid,
          cameras: [],
        }

        for (const camera of cameras) {
          const cameraExid = camelize(camera.id, { separator: "-" })
          const cameraShares = {}

          if (projectShares) {
            for (const share of projectShares) {
              if (!this.users.find((header) => header.email === share.email)) {
                this.users.push({
                  email: share.email,
                  name: share.fullname,
                })
              }
              if (share.cameras && share.cameras[cameraExid]) {
                cameraShares[share.email] = {
                  rights: this.getCameraPermission(share.cameras[cameraExid]),
                }
              }
            }
          }

          projectData.cameras.push({
            exid: camera.id,
            name: camera.name,
            shares: cameraShares,
          })
        }
        this.users.sort((a, b) => a.name.localeCompare(b.name))
        projects.push(projectData)
      }

      this.projects = projects
    },
    async fetchProjectShares() {
      const projectShares = await Promise.all(
        this.projectStore.projects.map(async (project) => {
          let shares = []
          try {
            shares = await EvercamApi.shares.getProjectShares(project.exid)
          } catch (error) {
            console.log(error)
          }

          return {
            name: project.name,
            exid: project.exid,
            shares: shares,
          }
        })
      )
      const projectShareObject = {}
      for (const projectShare of projectShares) {
        projectShareObject[projectShare.exid] =
          projectShare.shares.projectShares || []
      }
      this.projectShares = projectShareObject
      this.formatProjectShares()
    },
    getUserProjectAccessLevel(email, projectExid) {
      const project = this.projects.find(
        (project) => project.exid === projectExid
      )

      if (!project) {
        return null
      } else if (project.cameras.every((camera) => !camera.shares[email])) {
        return null
      } else if (
        project.cameras.every(
          (camera) => camera.shares[email]?.rights === SharePermission.Minimum
        )
      ) {
        return SharePermission.Minimum
      } else if (
        project.cameras.every(
          (camera) => camera.shares[email]?.rights === SharePermission.Share
        )
      ) {
        return SharePermission.Share
      } else if (
        project.cameras.every(
          (camera) => camera.shares[email]?.rights === SharePermission.Full
        )
      ) {
        return SharePermission.Full
      } else {
        return SharePermission.Custom
      }
    },
    getCameraAccessLevel(email, cameraExid) {
      for (const project of this.projects) {
        for (const camera of project.cameras) {
          if (camera.exid === cameraExid) {
            return camera.shares[email]?.rights || null
          }
        }
      }
    },
    async handleCameraShare(
      user,
      oldPermission,
      cameraExid,
      projectExid,
      event
    ) {
      const newPermission = event?.value
      const shareData = this.getShareData(user, newPermission)
      try {
        if (
          oldPermission === newPermission ||
          !this.userCanShareCamera(cameraExid)
        ) {
          return
        } else if (oldPermission && newPermission) {
          await this.updateShare(cameraExid, shareData)
        } else if (!oldPermission && newPermission) {
          await this.createShare(cameraExid, shareData)
        } else if (oldPermission && !newPermission) {
          await this.deleteShare(cameraExid, user.email)
        }

        this.updateMember(user.email, cameraExid, projectExid, newPermission)
      } catch (error) {
        this.$notifications.error({
          text: "Camera share failed :",
          error,
        })

        this.updateMember(user.email, cameraExid, projectExid, oldPermission)
      }
    },
    handleProjectShare(user, oldPermission, projectExid, event) {
      const project = this.projects.find(
        (project) => project.exid === projectExid
      )

      for (const camera of project.cameras) {
        const oldCameraPermission = this.getCameraAccessLevel(
          user.email,
          camera.exid
        )
        this.handleCameraShare(
          user,
          oldCameraPermission,
          camera.exid,
          projectExid,
          event
        )
      }
    },
    getShareData(user, newPermission) {
      return {
        email: user.email,
        rights: this.generateRightList(newPermission),
      }
    },
    updateMember(email, cameraExid, projectExid, newPermission) {
      this.projects = [...this.projects] //force computed properties to update
      const project = this.projects.find(
        (project) => project.exid === projectExid
      )
      const camera = project.cameras.find(
        (camera) => camera.exid === cameraExid
      )

      if (newPermission) {
        camera.shares[email] = {
          rights: newPermission,
        }
      } else {
        delete camera.shares[email]
      }
    },
    generateRightList(permissions) {
      let rights = ["list", "view", "snapshot"]

      if (permissions === SharePermission.Share) {
        rights = [...rights, "share"]
      } else if (permissions === SharePermission.Full) {
        rights = [...rights, "share", "edit"]
      }

      return rights.join(",")
    },
    async createShare(cameraExid, shareData) {
      await EvercamApi.shares.cCreate(cameraExid, shareData)
      this.$notifications.success("Camera successfully shared with the user.")
    },
    async updateShare(cameraExid, shareData) {
      await this.$axios.patch(`/cameras/${cameraExid}/shares`, shareData)
      this.$notifications.success("Rights successfully updated.")
    },
    async deleteShare(cameraExid, email) {
      await EvercamApi.shares.deleteShare(cameraExid, { params: { email } })
      this.$notifications.success("Camera successfully unshared with the user.")
    },
    handleTableResize() {
      this.$nextTick(() => {
        if (!this.$refs.grid) {
          return
        }
        const height =
          window.innerHeight - this.$refs.grid.getBoundingClientRect().top
        const computedHeight = `${height - 70}px`
        this.$refs.grid.style.height = computedHeight
        this.$refs.sidebar.style.height = computedHeight
      })
    },
    getThumbnailUrl(exid) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${this.$config.public.apiURL}/cameras/${exid}/thumbnail?authorization=${this.accountStore.token}`
      )
    },
    handleImageError(e) {
      e.target.src = "/unavailable.jpg"
    },
    nextPage() {
      if (this.endRange < this.totalUsers) {
        this.startRange += this.itemsPerPage
        this.endRange = Math.min(
          this.endRange + this.itemsPerPage,
          this.totalUsers
        )
      }
    },
    previousPage() {
      if (this.startRange > 1) {
        this.startRange = Math.max(this.startRange - this.itemsPerPage, 1)
        this.endRange = this.startRange + this.itemsPerPage
      }
    },
    userCanShareCamera(cameraExid) {
      const cameraRights = this.cameraStore.getCameraByExid(cameraExid)?.rights
      const canUserShareCamera =
        cameraRights.indexOf("share") !== -1 ||
        cameraRights.indexOf("edit") !== -1

      return canUserShareCamera
    },
  },
}
